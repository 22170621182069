footer{
  padding-bottom: 30px;
  .footer-site-info{
    border-top: 1px solid $light-grey;
    padding-top: 50px;
    padding-bottom: 20px;
    text-align: center;
    margin-top: 50px;
    .footer-navigation{
      float: none;
      .footer-menu{
        li{
          display: inline-block;
          margin-right: 80px;
          a{
            color: $dark-grey;
            font-size: 18px;
            font-family: 'istok_webregular';
            font-weight: normal;
          }
          @include breakpoint(md){
            margin-right: 10px;
          }
        }
      }
      @include breakpoint(screen_768){
        float: left;
      }
    }
    .social-navigation{
      float: none;
      margin-top: 0;
      text-align: center;
      ul.social-links-menu{
        li{
          padding-right: 15px;
          &:last-child{
            padding-right: 0;
          }
          a{
            svg{
              width: 24px;
              height: 24px;
              color: $red;
            }
          }
        }
      }
      @include breakpoint(screen_768){
        float: right;
        text-align: left;
      }
    }
    @include breakpoint(screen_768){
      text-align: left;
    }
  }
}