/*fonts start*/

/*fonts end*/

/*breakpoints start*/
@mixin breakpoint($point) {
  @if $point==screen_1600 {
    @media (min-width: 1600px) {
      @content;
    }
  } //screen

  @else if $point==screen_1240 {
    @media (min-width: 1240px) {
      @content;
    }
  } //screen

  @else if $point==screen_992 {
    @media (min-width: 992px) {
      @content;
    }
  } //screen

  @else if $point==screen_768 {
    @media (min-width: 768px) {
      @content;
    }
  } //screen

  @else if $point==screen_600 {
    @media (min-width: 600px) {
      @content;
    }
  } //screen

  @else if $point==screen_1440 {
    @media (max-width: 1440px) {
      @content;
    }
  } //screen

  @else if $point==md {
    @media (max-width: 992px) {
      @content;
    }
  } //screen

  @else if $point==m {
    @media (max-width: 767px) {
      @content;
    }
  } //screen

  @else if $point==sm {
    @media (max-width: 480px) {
      @content;
    }
  } //screen

  @else{
    @media (max-width: $point) {
      @content;
    }
  }
}
/*breakpoints end*/

/*border radius start*/
@mixin border-radius($topLeft, $topRight, $bottomRight, $bottomLeft){
  border-radius: $topLeft $topRight $bottomRight $bottomLeft;
  -moz-border-radius: $topLeft $topRight $bottomRight $bottomLeft;
  -webkit-border-radius: $topLeft $topRight $bottomRight $bottomLeft;
}
/*border radius end*/

/*box-shadow start*/
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}
/*box-shadow end*/

/*blur start*/
@mixin blur($val){
  filter: blur($val);
  -moz-filter: blur($val);
  -webkit-filter: blur($val);
}
/*blur radius end*/

@mixin placeholder {
  input::-webkit-input-placeholder {@content}
  input::-moz-placeholder           {@content}
  input::-moz-placeholder          {@content}
  input::-ms-input-placeholder      {@content}  
}

@-moz-keyframes bgLines /* Firefox */ {
  0% {
    top:-50px;
  }
  100% {
    top:calc(100% + 50px);
  }
}
@-webkit-keyframes bgLines /* Safari and Chrome */ {
  0% {
    top:-50px;
  }
  100% {
    top:calc(100% + 50px);
  }
}

