.contact-section, .newsletter{
  background: url(../../images/contact-us.jpg);
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  height: 200px;
  margin-top: 20px;
  text-align: center;
  @include breakpoint(sm){
    height: auto;
    padding: 20px 0;
  }
  .wp-block-column{
    margin: 0;
    @include breakpoint(md){
      flex-basis: 100%;
      flex-grow: 1;
      text-align: center!important;
    }
  }
  h3, .learn-more{
    margin: 0px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    @include breakpoint(md){
      position: inherit!important;
      top: 0;
      transform: inherit;
      margin: 1rem 0 0;
      float: none !important;
      right: auto !important;
      left: auto!important;
    }
  }
  h3{
    left: 20px;
    font-size: 32px;
    color: $white;
    font-family: 'fjalla_oneregular';
    text-transform: inherit;
    @include breakpoint(sm){
      left: auto;
      margin-bottom: 0px;
      font-size: 24px;
    }
    @include breakpoint(screen_1240){
      font-size: 40px;
      left: 100px;
    }
    @include breakpoint(screen_600){
      position: absolute;
    }
  }
  .learn-more{
    right: 10px;
    float: none;
    a{
      background: none;
      color: $white;
      font-size: 18px;
      font-family: 'istok_webbold';
      padding: 0 55px 0px;
      &:after{
        background: url(../../images/arrow-right-white.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
      @include breakpoint(md){
        padding-left: 0;
      }
    }
    @include breakpoint(screen_1240){
      right: 100px;
    }
    @include breakpoint(screen_600){
      position: absolute;
      float: right;
    }
  }
  @include breakpoint(md){
    flex-wrap: wrap;
    padding: 0;
  }
  @include breakpoint(screen_600){
    margin-top: 100px;
    text-align: left;
  }
}
.contact-section{
  .wp-block-column {
    text-align: left;
  }
}
.newsletter {
  background: url(../../images/newsletter-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  margin-bottom: 100px;
  height: 250px;

  .wp-block-column {
  &:last-child{
    p{
      left: auto;
      right: 50px;
      top: 13%;
      transform: translateY(13%);
    }
    @include breakpoint(md){
      p{
        right: 0;
      }
    }
  }
  h3 {
    position: absolute;
    top: 65px;
    left: 100px;
    color: $white;
    font-family: 'fjalla_oneregular';
    text-transform: inherit;
    @include breakpoint(md){
      position: inherit;
      top: 0;
      left: 0;
      transform: inherit;
      padding-left: 20px;
      padding-top: 20px;
      margin-top: 0;
    }
    @include breakpoint(sm){
      padding-top: 0;
      padding-left: 10px;
    }
  }

  p {
    position: absolute;
    top: 110px;
    transform: translateY(-50%);
    color: $white;
    left: 100px;
    @include breakpoint(md){
      position: inherit;
      top: 0;
      left: 0;
      transform: inherit;
      padding-left: 20px;
      margin: 10px 0;
    }
    @include breakpoint(sm){
      padding-left: 10px;
    }
  }

  span {
    &.sub-btn{
      position: relative;
      width: 100px;
      height: 50px;
      top: 10px;
      &:before{
        content: '';
        display: block;
        vertical-align: middle;
        width: 47px;
        height: 47px;
        position: absolute;
        right: 0px;
        top: 0px;
        transition: all 0.3s ease;
        border: 2px solid #c50f2f;
        margin-right: -30px;
        margin-top: 0;
      }
      &:after{
        background: url(../../images/arrow-right-white.svg);
        background-repeat: no-repeat;
        background-position: center;
        content: '';
        display: block;
        vertical-align: middle;
        width: 23px;
        height: 30px;
        position: absolute;
        right: 0px;
        top: 8px;
        transition: all 0.3s ease;
        margin-right: -20px;
      }
      &:hover{
        &:after{
          transform: translateX(7px);
        }
      }
    }
      @include breakpoint(md){
        width: 100%;
      }
  }

  input[type="email"],  input[type="text"]{
    background: none;
    border: none;
    border-bottom: 1px solid #fff;
    max-width: 100%;
    color: $white;
    padding: 10px 0;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $white;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $white;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $white;
    }

    &:focus {
      outline: 0;
    }

    @include breakpoint(sm){
      max-width: 240px;
    }
  }

  .wpcf7-submit {
    background: none;
    padding: 0;
    width: 127px;
    text-align: left;
    z-index: 10;
    padding: 13px 0;
    @include breakpoint(md){
      float: left;
    }

    &:focus {
      outline: 0;
      outline-offset: 0px;
    }
  }

  .wpcf7-response-output {
    position: absolute;
    color: $white;
    left: 100px;
    margin: 0;
    top: 10px;
    @include breakpoint(md){
      position: inherit;
      left: auto;
      margin: 10px 0 0 0;
      float: left;
      width: 100%;
    }
  }
    @include breakpoint(md){
      flex-basis: 100%;
      flex-grow: 1;
    }
}
  @include breakpoint(md){
    flex-wrap: wrap;
    text-align: left;
    height: auto;
    padding: 20px 10px;
  }
}
.contact-map{
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  margin-left: calc(-100vw / 2 + 1200px / 2);
  margin-right: calc(-100vw / 2 + 1200px / 2);
  margin-top: -100px;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
  }
  @include breakpoint(md){
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -30px;
  }
  @include breakpoint(screen_768){
    margin-left: calc(-100vw / 2 + 768px / 2);
    margin-right: calc(-100vw / 2 + 768px / 2);
  }
  @include breakpoint(screen_600){
    margin-left: calc(-100vw / 2 + 600px / 2);
    margin-right: calc(-100vw / 2 + 600px / 2);
  }
}
.contact-details{
  -webkit-box-shadow: 0px 9px 35px 0px rgba(169,181,194,0.22);
  -moz-box-shadow: 0px 9px 35px 0px rgba(169,181,194,0.22);
  box-shadow: 0px 9px 35px 0px rgba(169,181,194,0.22);
  padding: 40px 60px;
  margin-top: -100px;
  z-index: 10;
  background: $white;
  margin-bottom: 100px;
  font-size: 20px;
  color: $blue;
  line-height: 30px;
  font-family: 'fjalla_oneregular';
  .wp-block-column{
    border-right: 1px solid $light-grey;
    margin-bottom: 0;
    a{
      color: #001f41;
    }
    .address{
      position: relative;
      padding-left: 65px;
      &:before{
        content: '';
        position: absolute;
        background: url(../../images/address-icon.svg);
        background-repeat: no-repeat;
        background-position: center;
        top: 0;
        left: 0px;
        width: 24px;
        height: 34px;
        @include breakpoint(sm){
          top: 10px;
        }
      }
      @include breakpoint(sm){
        padding-left: 0;
        padding-top: 50px;
      }
    }
    .contact-no{
      position: relative;
      padding-left: 65px;
      &:before{
        content: '';
        position: absolute;
        background: url(../../images/phone-icon.svg);
        background-repeat: no-repeat;
        background-position: center;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 32px;
        height: 34px;
        @include breakpoint(sm){
          top: 10px;
        }
      }
      @include breakpoint(sm){
        padding-left: 0;
        padding-top: 40px;
      }
    }
    .email-address{
      position: relative;
      padding-left: 65px;
      &:before{
        content: '';
        position: absolute;
        background: url(../../images/email-icon.svg);
        background-repeat: no-repeat;
        background-position: center;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 32px;
        height: 34px;
        @include breakpoint(sm){
          top: 10px;
        }
      }
      @include breakpoint(sm){
        padding-left: 0;
        padding-top: 40px;
      }
    }
    &:last-child{
      border-right: 0;
    }
    @include breakpoint(md){
      border-bottom: 1px solid #e9e9e9;
      border-right: 0;
      margin-left: 0;
      &:last-child{
        border-bottom: 0;
      }
    }
  }
  @include breakpoint(md){
    padding: 10px 10px;
    display: block;
    margin-bottom: 60px;
    margin-top: 30px;
  }
  @include breakpoint(sm) {
    padding: 30px;
  }
}
form{
  .form-group {
    position: relative;
    margin-bottom: 20px;
    span.label {
      position: absolute;
      top: 18px;
      left: 19px;
      z-index: 1;
      background: #fff;
      display: inline-block;
      padding: 0 5px;
      transition: all 0.3s ease;
      font-size: 16px;
    }
    &.active{
      span.label{
        z-index: 3;
        color: #c50f2f;
        font-size: 14px;
        line-height: 14px;
        transform: translateY(-24px);
      }
      &.half-field {
        input {
          border-color: #c50f2f;
        }
      }
      &.full-field {
        textarea {
          border-color: #c50f2f;
        }
      }
    }
    &.half-field{
      display: inline-block;
      width: 32.3%;
      input {
        max-width: 390px;
        width: 100%;
        border-color: #eef2f4;
        padding: 1rem 1rem;
        &:focus{
          outline: none;
          outline-offset: 0;
        }
        @include breakpoint(md){
          max-width: 100%;
        }
      }
      @include breakpoint(md){
        width: 100%;
      }
      .wpcf7-not-valid-tip{
        font-size: 16px;
        color: #c50f2f;
      }
    }
    span.wpcf7-form-control-wrap{
      position: relative;
      z-index: 2;
      width: 97%;
      input{
        background: transparent;
        font-size: 24px;
        color: $blue;
      }
      textarea{
        max-width: 100%;
        width: 100%;
        border-color: #eef2f4;
        background: transparent;
        &:focus{
          outline: none;
          outline-offset: 0;
        }
      }
    }
  }
  .full-field{
    .focus-bg{
      position: relative;
      &:before {
        content: '';
        display: block;
        vertical-align: middle;
        width: 47px;
        height: 47px;
        position: absolute;
        right: 0px;
        top: 0px;
        transition: all 0.3s ease;
        border: 2px solid $red;
      }
      &:after{
        background: url(../../images/arrow-right.svg);
        background-repeat: no-repeat;
        background-position: center;
        content: '';
        display: block;
        vertical-align: middle;
        width: 47px;
        height: 47px;
        position: absolute;
        right: 0px;
        top: 0px;
        transition: all 0.3s ease;
      }
      &:hover {
        &:after {
          transform: translateX(7px);
        }
      }
      .wpcf7-submit {
        background: none;
        color: $grey;
        padding: 14px 45px 14px 0px;
        width: 100%;
        z-index: 10;
        &:focus{
          outline: 0;
        }
      }
    }
  }
}
.wpcf7-mail-sent-ok, .wpcf7-validation-errors{
  font-size: 16px;
}
.wpcf7-validation-errors{
  border-color: $red !important;
}
.get-in-touch{
  color: $blue;
  font-family: 'fjalla_oneregular';
  font-weight: normal;
  margin-bottom: 15px;
}
.get-in-touch-info{
  margin-bottom: 60px;
  a{
    color: $blue;
  }
}