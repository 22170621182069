.slider_components{
  position: relative;
  left: 150px;
  top: 0;
  max-width: 300px;
  width: 100%;
  transition: all 0.8s ease;
  display: none;
  z-index: 1000;
  ul.tab-list {
    width: 100%;
    position: absolute;
    top: 80%;
    transform: translateY(80%);
    left: 30px;
    transition: all 0.3s ease;
    li {
      display: block;
      margin-bottom: 15px;
      a {
        display: block;
        font-family: 'istok_webregular';
        color: $grey;
        font-size: 16px;
        text-align: left;
        &.active{
          color: $red;
          font-family: 'istok_webbold';
        }
        &:focus{
          outline: 0;
          text-decoration: none;
        }
      }
    }

  }
  .scroll-line{
    width: 1px;
    height: 300px;
    position: absolute;
    left: 0px;
    top: 170px;
    background: $light-grey;
    overflow: hidden;
    .scrolled-face{
      width: 1px;
      height: 0%;
      background: $red;
    }
    &.normal{
      top: 130px;
      position: fixed;
    }
  }
  .upDown-buttons{
    position: absolute;
    top: 480px;
    left: 0px;
    margin-left: -9px;
    width: 20px;
    height: 30px;
    a{
      width: 18px;
      height: 20px;
      font-size: 0;
      padding: 0;
      &#up{
        background: url(../../images/slider-up.svg);
        background-repeat: no-repeat;
        background-position: center;
        &:hover{
          background: url(../../images/slider-up-hover.svg);
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      &#down{
        background: url(../../images/slider-down.svg);
        background-repeat: no-repeat;
        background-position: bottom;
        &:hover{
          background: url(../../images/slider-down-hover.svg);
          background-repeat: no-repeat;
          background-position: bottom;
        }
      }
      &:focus{
        outline: 0;
      }
    }
  }
  .total_count{
    position: absolute;
    bottom: 100%;
    margin-bottom: 16px;
    left: -35px;
    font-size: 16px;
    color: $light-grey;
    top: 125px;
    transform: rotate(270deg);
    .current_count{
      color: $red;
    }
  }
  &.normal{
    position: fixed;
    max-width: 300px;
    width: 100%;
  }
  &.update-component{
    position: absolute;
    top: auto;
    transition: all 0.5s ease;
  }
  @include breakpoint(screen_1240){
    left: 60px;
    display: block;
  }
  @include breakpoint(screen_1600){
    left: 200px;
    display: block;
  }
}
.page-template-risks_template {
  .custom-list-slider {
    position: relative;
    .tab-content-wrapper {
      margin-left: 0px;
      max-width: 100%;
      width: 100%;
      /*height: 500px;
      overflow: auto;*/
      .page_sections {
        padding: 65px 0;
        position: relative;
        margin-left: 0px;
        .resp-container {
          position: relative;
          overflow: hidden;
          padding-top: 56.25%;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
          }
        }
        @include breakpoint(screen_1240){
          margin-left: 150px;
        }
        .container{
          @include breakpoint(screen_1240) {
            max-width: 790px;
          }
        }
        &#tab_0{
          background: url(../../images/rising-sea-bg.svg);
          background-position: left bottom;
          background-repeat: no-repeat;
        }
        &#tab_1{
          background: url(../../images/extream-strorm-bg.svg);
          background-position: left bottom;
          background-repeat: no-repeat;
        }
        &#tab_2{
          background: url(../../images/extream-heat-bg.svg);
          background-position: left bottom;
          background-repeat: no-repeat;
        }
        &#tab_3{
          background: url(../../images/ice-melt-bg.svg);
          background-position: left bottom;
          background-repeat: no-repeat;
        }
        &:after{
          background: #fafafa;
          content: '';
          width: 100%;
          height: 79px;
          bottom: 0px;
          position: absolute;
        }

        h2 {
          font-family: 'fjalla_oneregular';
          font-weight: normal;
          position: relative;
          margin-bottom: 0px;
          margin-top: 50px;
        }

        h3{
          font-size: 28px;
          margin-top: 30px;
          line-height: normal;
          text-transform: inherit;
          font-family: inherit;
          font-weight: bold;
          color: $red;

          &.sub-title{
            position: relative;
            margin-bottom: 60px;
            text-transform: inherit;
            color: inherit;
            &:before {
              position: absolute;
              left: 0;
              bottom: -20px;
              width: 68px;
              height: 2px;
              content: '';
              background: $red;
              display: block;
            }
          }
        }

        h4{
          margin-top: 10px;
          font-family: 'fjalla_oneregular';
          font-weight: normal;
          line-height: 30px;
        }

        p {
          font-size: 18px;
          font-family: 'istok_webregular';
          &.img-caption{
            font-size: 16px;
            font-style: italic;
            margin-top: -10px;
            color: #ffffffc7;
            background: #001f41;
            text-align: center;
            padding: 5px 10px;
            line-height: 25px;
          }
          iframe{
            margin-bottom: -20px;
          }
        }

        a{
          color: $red;
          text-decoration: underline;
          &:hover{
            text-decoration: none;
          }
        }

        ul{
          li{
            position: relative;
            padding-left: 30px;
            &:before{
              width: 12px;
              height: 12px;
              background: $red;
              display: inline-block;
              content: '';
              left: 0;
              position: absolute;
              top: 7px;
            }
          }
        }

        .grid{
          ul{
            li{
              padding-left: 0;
              padding-bottom: 0;
              &:before{
                display: none;
              }
            }
          }
        }

        img{
          margin-top: 15px;
          width: 100%;
          &.risk-icon{
            width: auto;
          }
        }

        a{
          display: inline;
        }

        .half-section {
          max-width: 50%;
          width: 100%;
          float: left;
          padding-top: 40px;

          &.img {
            img {
              text-align: right;
              float: right;
              @include breakpoint(md){
                float: left;
                text-align: left;
              }
            }
          }
          @include breakpoint(screen_1240){
            max-width: 390px;
          }
          @include breakpoint(md){
            max-width: 100%;
          }
        }

        .grid {
          -webkit-column-count: 1;
          -moz-column-count: 1;
          column-count: 1;
          @include breakpoint(screen_600){
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
          }
          ul {
            display: block;
            width: 100%;
          }

          .grid-item {
            width: 100%;
            display: block;
            img {
              width: 100%;
            }
          }

          .grid-item--width2 {
            width: 100%
          }
        }
      }
    }
  }
  .contact-section{
    margin-bottom: 80px;
    position: relative;
    bottom: 0;
    .learn-more{
      &:after{
        background: url(../../images/arrow-right-white.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
/*navigate buttons*/
.navigate-buttons{
  position: absolute;
  top: 0px;
  left: 20px;
  a{
    width: 30px;
    height: 30px;
    position: absolute;
    display: none;
    &.up{
      top: 0px;
      background: url(../../images/slider-up.svg);
      background-repeat: no-repeat;
      background-position: center;
      &:hover{
        background: url(../../images/slider-up-hover.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    &.down{
      top: 45px;
      background: url(../../images/slider-down.svg);
      background-repeat: no-repeat;
      background-position: center;
      &:hover{
        background: url(../../images/slider-down-hover.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    &:focus{
      outline: none;
    }
  }
}