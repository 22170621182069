.related-wrap {
  .related-posts {
    display: block;
    margin: 0 -15px 0 -15px;
    padding: 0;

    li {
      display: block;
      float: left;
      width: 100%;
      padding: 0 15px;
      margin-bottom: 30px;

      .post-img {
        height: 200px;
        overflow: hidden;
        @include breakpoint(md){
          height: 150px;
        }
        @include breakpoint(m){
          height: auto;
          display: flex;
        }
        img {
          width: 100%;
        }
        @include breakpoint(screen_992){
          height: 180px;
        }
        @include breakpoint(screen_1240){
          height: 244px;
        }
      }

      .post-description {
        border: 1px solid $light-grey;
        border-bottom: 5px solid $light-grey;
        padding: 15px;
        min-height: 263px;
        @include breakpoint(md){
          min-height: auto;
          border-top: none;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 0%;
          height: 5px;
          background: $red;
          left: 0px;
          bottom: -5px;
          z-index: 10;
          transition: all 0.3s ease;
        }

        h3 {
          font-size: 14px;
          font-family: 'istok_webbold';
          text-transform: uppercase;
          margin-top: 0;
          line-height: 20px;
        }

        p {
          font-size: 20px;
          font-family: 'fjalla_oneregular';
          @include breakpoint(screen_768){
            font-size: 16px;
          }
          @include breakpoint(screen_992){
            font-size: 20px;
          }
        }

        .learn-more {
          font-family: 'istok_webbold';
          font-size: 14px;

          span {
            opacity: 0;
          }

          &:before {
            border: none;
          }

          &:after {
            left: 0;
          }

          &:hover {
            span {
              opacity: 1;
            }

            &:after {
              transform: translateX(100px);
            }
          }
        }
        @include breakpoint(screen_992){
          padding: 25px;
        }
        @include breakpoint(screen_1240){
          padding: 35px 20px;
        }
      }

      &:hover {
        .post-description {
          &:after {
            width: 100%;
          }
        }

        .learn-more {
          color: $red;

          span {
            opacity: 1;
          }

          &:after {
            transform: translateX(100px);
            background: url(../../images/learn-more-hover.svg);
            background-repeat: no-repeat;
            background-position: center;
          }
        }

      }

      &.related-li {
        margin-bottom: 80px;
      }
      @include breakpoint(md){
        padding: 0 10px;
      }
      @include breakpoint(screen_768){
        width: 33.33%;
        margin-bottom: 0px;
      }
    }
  }
  .pagination {
    text-align: center;

    .learn-more {
      text-transform: uppercase;
      font-family: istok_webbold;
      padding-right: 60px;
    }
  }
}
