@font-face {
	font-family: 'fjalla_oneregular';
	src: url('../../fonts/fjallaone/fjallaone-regular-webfont.woff2') format('woff2'),
	url('../../fonts/fjallaone/fjallaone-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}
@font-face {
	font-family: 'istok_webbold';
	src: url('../../fonts/istokweb/istokweb-bold-webfont.woff2') format('woff2'),
	url('../../fonts/istokweb/istokweb-bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'istok_webbolditalic';
	src: url('../../fonts/istokweb/istokweb-bolditalic-webfont.woff2') format('woff2'),
	url('../../fonts/istokweb/istokweb-bolditalic-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}
@font-face {
	font-family: 'istok_webitalic';
	src: url('../../fonts/istokweb/istokweb-italic-webfont.woff2') format('woff2'),
	url('../../fonts/istokweb/istokweb-italic-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}
@font-face {
	font-family: 'istok_webregular';
	src: url('../../fonts/istokweb/istokweb-regular-webfont.woff2') format('woff2'),
	url('../../fonts/istokweb/istokweb-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}
body{
	background: $white;
	color: $grey;
	font-family: 'istok_webregular';
	font-size: 18px;
	line-height: 29px;
}
*{
	box-sizing: border-box;
	position: relative;
	transition: all 0.3s ease;
}
.container{
	width: 100%;
	margin: 0 auto;
	padding-left: 15px;
	padding-right: 15px;

	@include breakpoint(screen_576){
		max-width: 540px;
	}
	@include breakpoint(screen_768){
		max-width: 720px;
	}
	@include breakpoint(screen_992){
		max-width: 960px;
		padding-left: 0px;
		padding-right: 0px;
	}
	@include breakpoint(screen_1240){
		max-width: 1200px;
		padding-left: 0px;
		padding-right: 0px;
	}



}
.clear{
	&:before, &:after{
		content: '';
		display: table;
		clear: both;
	}
}
h1{
	font-size: 32px;
	line-height: normal;
	&:before{
		display: none;
	}
	@include breakpoint(screen_768){
		font-size: 40px;
	}
	@include breakpoint(screen_992){
		font-size: 50px;
	}
	@include breakpoint(screen_1240){
		font-size: 60px;
	}
}
h2{
	&:before{
		display: none;
	}
}
h3{
	font-size: 30px;
	font-family: 'fjalla_oneregular';
	font-weight: normal;
	text-transform: uppercase;
}
a, span{
	display: inline-block;
	text-decoration: none;
}
a:focus{
	outline: 0;
	text-decoration: none;
}
ul{
	margin: 0px;
	padding: 0px;
	li{
		display: inline-block;
		line-height: 29px;
		font-size: 18px;
		padding-bottom: 20px;
		a{
			display: block;
		}
	}
}
.hide{
	display: none !important;
}
.page-header{
	background: $blue url(../../images/pattern_header.svg);
	margin-top: -32px;
	text-align: center;
	padding-top: 120px;
	padding-bottom: 60px;
	margin-bottom: 30px;
	overflow: hidden;
	border-bottom: 2px solid $red;
	.background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		overflow: hidden;
		.backgroundCol {
			position: absolute;
			top: 0;
			left: 0;
			width: 33.33%;
			height: 100%;
			text-align: center;
			&.sec{
				left: 33%;
				.backgroundColLine{
					span{
						height: 55px;
						-webkit-animation-delay: 30s;
						animation-delay: 30s;
						&.second-line-med{
							height: 40px;
							top: 50px;
							animation: bgLines 90s;
							-moz-animation: bgLines 90s infinite;
							-webkit-animation: bgLines 90s infinite;
						}
						&.second-line-small{
							height: 20px;
							top: 100px;
							animation: bgLines 90s;
							-moz-animation: bgLines 90s infinite;
							-webkit-animation: bgLines 90s infinite;
						}
					}
				}
			}
			&.thrd {
				left: 66%;
				.backgroundColLine{
					span{
						height: 55px;
						-webkit-animation-delay: 30s;
						animation-delay: 30s;
						&.second-line-small{
							top: 50px;
							height: 20px;
							animation: bgLines 90s;
							-moz-animation: bgLines 90s infinite;
							-webkit-animation: bgLines 90s infinite;
						}
					}
				}
			}
			.backgroundColLine {
				width: 1px;
				height: 100%;
				display: inline-block;
				position: relative;
				background: #022d59;
				span {
					position: absolute;
					top: -50px;
					left: -1px;
					width: 3px;
					background: #2060a3;
					height: 55px;
					animation: bgLines 90s;
					-moz-animation: bgLines 90s infinite;
					-webkit-animation: bgLines 90s infinite;
					&.second-line{
						top: 50px;
						animation: bgLines 90s;
						-moz-animation: bgLines 90s infinite;
						-webkit-animation: bgLines 90s infinite;
					}
				}
			}
		}
	}
	h1{
		color: $white;
		font-family: 'fjalla_oneregular';
		letter-spacing: 3px;
		max-width: 920px;
		margin: 18px auto 40px auto;
		position: relative;
		display: inline-block;
		&:not(.site-title):before {
			display: none;
		}
		&:after{
			width: 110px;
			height: 2px;
			background: #c50f2f;
			position: absolute;
			content: '';
			left: 0;
			bottom: -10px;
			@include breakpoint(sm){
				right: 0;
				margin: 0 auto;
			}
		}
	}
	h4{
		font-family: 'istok_webbold';
		font-size: 16px;
		letter-spacing: 4px;
		text-transform: uppercase;
		color: $white;
		display: none;
		/*&:before{
			content: '';
			display: block;
			position: absolute;
			width: 5px;
			height: 5px;
			background: $red;
			margin-left: -25px;
			top: 7px;
		}*/
	}
	p{
		max-width: 1000px;
		margin: 0 auto;
		color: $white;
		font-size: 18px;
		line-height: 29px;
		span{
			display: inline;
		}
		a{
			color: $red;
			font-weight: bold;
		}
	}

	.header-line{
		display: none;
	}
	&.lg{
		padding-top: 140px;
		padding-bottom: 140px;
		margin-bottom:  0px;
		position: relative;
		border-bottom: none;
		.header-line{
			position: absolute;
			width: 102%;
			top: 100%;
			left: -20px;
			right: 0px;
			height: 105px;
			margin-top: -102px;
			display: none;
			@include breakpoint(md){
				margin-top: -102px;
				left: -10px;
			}
		}
		&:after{
			content: '';
			width: 101%;
			height: 124px;
			position: absolute;
			top: 100%;
			margin-top: -62px;
			left: -5px;
			right: -5px;
			/* background: linear-gradient(to left bottom, transparent 49%, #fff 50%); */
			transform: rotate(3.4deg);
			background: #fff;
		}
		&:before{
			content: '';
			display: block;
			position: absolute;
			left: -5px;
			right: -5px;
			height: 13px;
			background: #c50f2f;
			border-top: 11px solid #fff;
			top: 100%;
			margin-top: -75px;
			transform: rotate(3.4deg);
			z-index: 1;
		}
		@include breakpoint(screen_768){
			padding-top: 200px;
			padding-bottom: 200px;
		}
	}
	@include breakpoint(screen_992){
		margin-bottom: 100px;
	}
	@include breakpoint(screen_600){
		padding-top: 190px;
		padding-bottom: 100px;
	}
}
.featured-slider{
	width: 100%;
	max-width: 100%;
	display: block;
	margin-top: -100px;
	margin-bottom: 100px;
	@include breakpoint(md){
		width: 75%;
		margin: 0 auto;
	}
	li{
		display: block;
		width: 100%;
		padding: 0 15px;
		.left{
			float: left;
			width: 100%;
			padding: 60px 0;
			@include breakpoint(sm){
				padding: 30px 0;
			}
			.icon{
				margin-bottom: 50px;
				@include breakpoint(sm){
					margin-bottom: 30px;
				}
				img{
					width: 53px;
				}
			}
			.slider_title{
				h3 {
					font-size: 36px;
					font-family: 'fjalla_oneregular';
					font-weight: normal;
					text-transform: inherit;
					@include breakpoint(sm){
						font-size: 30px;
					}
					&:after{
						width: 70px;
						height: 2px;
						background: $red;
						position: absolute;
						content: '';
						left:0;
						bottom: -15px;
					}
					@include breakpoint(screen_768){
						font-size: 50px;
					}
				}
			}
			.slider_desc{
				margin-top: 60px;
				@include breakpoint(sm){
					margin-top: 40px;
				}
			}
			p{
				font-size: 18px;
				line-height: 29px;
				@include breakpoint(sm){
					font-size: 16px;
					line-height: 25px;
				}
			}
			.load_more{
				.learn-more{
					color: $grey;
					text-transform: uppercase;
					font-family: 'istok_webbold';
					padding-right: 60px;
					@include breakpoint(sm){
						font-size: 14px;
						padding-right: 60px;
					}
				}
			}
			@include breakpoint(screen_992){
				width: 470px;
			}
		}
		.right{
			float: none;
			width: 100%;
			height: auto;
			overflow: hidden;
			img{
				width: 100%;
			}
			@include breakpoint(screen_992){
				float: right;
				width: 450px;
				height: 470px;
			}
			@include breakpoint(screen_1240){
				float: right;
				width: 510px;
				height: 530px;
			}
		}
		@include breakpoint(screen_992){
			padding: 0;
		}
	}
	&.owl-carousel{
		.owl-nav{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
			z-index: 100;
			left: 0;
			right: 0;
			text-align: center;
			margin-top: 40px;
			button{
				border: 2px solid $grey;
				width: 47px;
				height: 47px;
				background: url(../../images/arrow-right.svg);
				background-repeat: no-repeat;
				background-position: center;
				cursor: pointer;
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				border-radius: 0px;
				font-size: 0px;
				opacity: 0.5;
				&.disabled{
					opacity: 0.1;
					&:hover{
						border-color: $grey;
						opacity: 0.1;
						cursor: not-allowed;
					}
				}
				&.owl-prev{
					transform: rotate(-180deg);
					margin-left: 0;
					left: -50px;
					float: left;
					@include breakpoint(screen_768){
						margin-left: -40px;
						left: auto;
					}
					@include breakpoint(screen_992){
						margin-left: -75px;
					}
				}
				&.owl-next{
					float: right;
					margin-right: 0px;
					right: -50px;
					@include breakpoint(screen_768){
						float: right;
						margin-right: -40px;
						right: auto;
					}
					@include breakpoint(screen_992){
						margin-right: -75px;
					}
				}
				&:hover{
					opacity: 1;
					border-color: $red;
				}
				&:focus {
					outline: none;
				}
			}
			@include breakpoint(screen_768){
				left: auto;
				right: auto;
				text-align: inherit;
			}
		}
		.owl-dots{
			bottom: 20px;
			position: absolute;
			button{
				width: 42px;
				height: 2px;
				background: $light-grey;
				margin-right: 15px;
				@include breakpoint(sm){
					width: 30px;
					margin-right: 10px;
				}
				&.active{
					background: $red;
				}
			}
		}
	}
}

a.learn-more{
	font-size: 18px;
	color: $grey;
	display: inline-block;
	line-height: 47px;
	@include breakpoint(sm){
		font-size: 16px;
	}
	&:before, &:after{
		content: '';
		display: block;
		vertical-align: middle;
		width: 47px;
		height: 47px;
		position: absolute;
		right: 0px;
		top: 0px;
		transition: all 0.3s ease;
		}
		&:before{
			border: 2px solid $red;
		}
		&:after{
			background: url(../../images/arrow-right.svg);
			background-repeat: no-repeat;
			background-position: center;
		}
		&:focus{
			outline: none;
		}
		&:hover{
			&:after{
				transform: translateX(7px);
			}
		}

}

.learn-more{
	font-size: 18px;
	color: $grey;
	display: inline-block;
	line-height: 47px;
	@include breakpoint(sm){
		font-size: 16px;
	}
	a{
		&:before, &:after{
			content: '';
			display: block;
			vertical-align: middle;
			width: 47px;
			height: 47px;
			position: absolute;
			right: 0px;
			top: 0px;
			transition: all 0.3s ease;
		}
		&:before{
			border: 2px solid $red;
		}
		&:after{
			background: url(../../images/arrow-right.svg);
			background-repeat: no-repeat;
			background-position: center;
		}
		&:focus{
			outline: none;
		}
		&:hover{
			&:after{
				transform: translateX(7px);
			}
		}
	}

}