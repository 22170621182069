header.header{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	padding: 12px 0;
	transition: all 0.3s ease;
	z-index: 10000;
	.header-logo{
		float: left;
		height: 63px;
	}
	.top-navigation{
		float: right;
		padding-right: 60px;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(50%);
		.main-menu-more{
			display: none;
		}
		.main-menu{
			position: relative;
			li {
				padding-right: 15px;
				padding-bottom: 0;
				&:last-child{
					padding-right: 0;
				}
				a{
					color: $white;
					font-size: 16px;
					font-family: 'istok_webregular';
					display: inline-block;
					&:focus {
						outline:0;
						text-decoration: none;
					}
					@include breakpoint(md){
						color: $grey;
						font-size: 32px;
						font-family: 'istok_webbold';
					}
					@include breakpoint(sm){
						font-size: 20px;
					}
				}
				&.current-menu-item{
					a{
						font-family: 'istok_webbold';
					}
				}
				&.visit{
					a {
						position: relative;
						font-family: 'istok_webbold';
						display: inline-block;
						&:before {
							content: '';
							display: block;
							vertical-align: middle;
							width: 47px;
							height: 47px;
							position: absolute;
							right: 0px;
							top: 0px;
							transition: all 0.3s ease;
							border: 2px solid #c50f2f;
							margin-right: -60px;
							margin-top: -8px;
							@include breakpoint(md){
								margin-top: 0;
								top: 50%;
								transform: translateY(-50%);
							}
						}

						&:after {
							background: url(../../images/arrow-right-white.svg);
							background-repeat: no-repeat;
							background-position: center;
							content: '';
							display: block;
							vertical-align: middle;
							width: 23px;
							height: 30px;
							position: absolute;
							right: 0px;
							top: 0px;
							transition: all 0.3s ease;
							margin-right: -48px;
							@include breakpoint(md){
								background: url(../../images/arrow-right.svg);
								background-repeat: no-repeat;
								background-position: center;
								top: 10px;
								margin-top: -10px;
							}
						}
						&:hover{
							&:after{
								transform: translateX(7px);
							}
						}
					}
				}
				@include breakpoint(screen_992){
					padding-right: 45px;
				}
				@include breakpoint(screen_1240){
					padding-right: 75px;
				}
				@include breakpoint(md) {
					display: block;
					width: 100%;
					text-align: left;
					padding: 10px 0;
				}
				.submenu-expand{
					display: inline-block;
					background: none;
					width: 22px;
					height: 24px;
					padding: 0px;
					vertical-align: middle;
					svg{
						fill: #ffffff;
						@include breakpoint(md){
							fill: #000000;
						}
					}
					@include breakpoint(md){
						display: none !important;
					}
				}
				ul.sub-menu{
					position: absolute;
					width: 208px;
					background: #FFF;
					top: 100%;
					padding: 10px 0px;
					visibility: hidden; /* hides sub-menu */
					opacity: 0;
					z-index: -1;
					transform: translateY(2em);
					transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
					-webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.33);
					-moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.33);
					box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.33);
					border-radius: 4px;
					li{
						padding: 5px 10px;
						width: 100%;
						line-height: 24px;
						&:first-child{
							display: none;
						}
						button{
							display: none;
						}
						a{
							color: #464646;
							font-family: 'istok_webregular';
						}
						&:hover{
							background: #001f41;
							a{
								color: #ffffff;
							}
						}
					}
					@include breakpoint(md){
						display: none !important;
					}
				}
				&:hover{
					.sub-menu{
						visibility: visible; /* shows sub-menu */
						opacity: 1;
						z-index: 1;
						transform: translateY(0%);
						transition-delay: 0s, 0s, 0.3s;
					}
				}
			}
		}
		@include breakpoint(md){
			opacity: 1;
			-webkit-transform: translateY(-25%) rotateX(35deg);
			transform: translateY(-25%) rotateX(35deg);
			-webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
			transition: transform 0.5s, opacity 0.5s;
			position: fixed;
			top: 50%;
			left: 50px;
			right: 0px;
			z-index: 1000;
			display: none;

		}
		@include breakpoint(sm){
			left: 20px;
		}
	}
	&.scrolled-header{
		background: $blue;
	}
	.toggle{
		position: absolute;
		width: 50px;
		height: 30px;
		right: 0px;
		top: 0px;
		background: none;
		z-index: 100;
		display: none;
		@include border-radius(100px, 100px, 100px, 100px);
		span{
			position: absolute;
			height: 2px;
			width: 17px;
			background: $white;
			right: 9px;
			transition: all 0.3s ease;
			&:before, &:after{
				content: '';
				display: block;
				position: absolute;
				right: 0px;
				height: 2px;
				width: 33px;
				top: -8px;
				background: $white;
				transition: all 0.3s ease;
			}
			&:after{
				top: 8px;
				width: 25px;
			}
		}
		&:hover{
			span{
				width: 33px;
				&:before, &:after{
					width: 33px;
				}
			}
		}
		@include breakpoint(md){
			&.hide{
				display: block;
			}
			display: block;
		}
	}
	.mobile-nav-bg{
		background: #FFF;
		position: fixed;
		top: 0px;
		right: 0px;
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease ;
		-ms-transition: all 0.3s ease ;
		-o-transition: all 0.3s ease ;
		transition: all 0.3s ease ;
		width: 0%;
		height: 0%;
		opacity: 0;
		button{
			width: 50px;
			height: 50px;
			position: absolute;
			right: 30px;
			top: 30px;
			background: none;
			&:before, &:after{
				content: '';
				display: block;
				position: absolute;
				width: 40px;
				height: 2px;
				background: #000;
				transform: rotate(45deg);
				right: 5px;
			}
			&:after{
				transform: rotate(-45deg);
			}
		}
	}
	&.active-nav{
		.mobile-nav-bg{
			width: 100%;
			height: 100%;
			-webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease ;
			-ms-transition: all 0.3s ease ;
			-o-transition: all 0.3s ease ;
			transition: all 0.3s ease ;
			opacity: 1;
		}
		.top-navigation{
			opacity: 1;
			-webkit-transform: rotateX(0deg);
			transform: rotateX(0deg) translateY(-50%);
			display: block;
		}
		.toggle{
			opacity: 0;
			z-index: -1;
		}
	}
}