@charset "UTF-8";
//helper
@import "helpers/_variables";
//mixins
@import "helpers/_mixins";
//components
@import "components/_normalize";
@import "components/_common";
@import "components/_header";
@import "components/_related_news";
@import "components/_contact";
@import "components/_footer";
@import "components/_about";
@import "components/_risks";
