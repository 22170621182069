
.about-content .wp-block-column:last-child{
  background: $half-white;
  padding: 0px 30px 30px;
  margin-top: 0px;
  max-width: 515px;
  float: right;
  @include breakpoint(screen_600){
    margin-top: 85px;
  }
}
.vision-mission{
  margin-top: 30px;
  h3{
    font-size: 26px;
  }
  p{
    font-size: 18px;
  }
  .col-icon{
    max-width: 45px;
  }
  @include breakpoint(screen_600){
    margin-top: 80px;
  }
}
.twitter-btn{
  position: absolute;
  top: 20px;
  right: 30px;
  @include breakpoint(md){
    position: inherit;
    top: auto;
    right: auto;
  }
  a{
    background-color: rgb(29, 161, 242);
    padding: 5px 30px 1px 27px;
    transition-property: background-color, box-shadow;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 8px 28px;
    font-weight: 600;
    &:hover{
      background-color: rgb(26, 145, 218);
    }
  }
}